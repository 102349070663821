import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isSafariBrowser from '@helpers/isSafari';
import { Parallax as ParallaxContainer } from 'react-scroll-parallax';

const Parallax = ({ children, y, x, className, contain }) => {
  const [isSafari, setIsSafari] = useState(true);
  useLayoutEffect(() => {
    if (isSafariBrowser() !== null) {
      setIsSafari(isSafariBrowser());
    }
  }, []);
  const renderParallax = () => (
    <ParallaxContainer
      translateY={y}
      translateX={x}
      easing="easeIn"
      className="transition transition-transform ease-in duration-0"
    >
      {children}
    </ParallaxContainer>
  );

  const renderContain = () =>
    contain ? (
      <div className={`overflow-hidden ${className}`}>{renderParallax()}</div>
    ) : (
      renderParallax()
    );

  return isSafari ? (
    <div
      className={`transition transition-transform ease-in duration-0 ${className}`}
    >
      {children}
    </div>
  ) : (
    renderContain()
  );
};

Parallax.propTypes = {
  children: PropTypes.node.isRequired,
  y: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  x: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  className: PropTypes.string,
  contain: PropTypes.bool,
};

Parallax.defaultProps = {
  className: '',
  x: [0, 0],
  contain: true,
};

export default Parallax;
