import { useStaticQuery, graphql } from 'gatsby';

const useFinishCategories = () => {
  const finishCategories = useStaticQuery(
    graphql`
      query {
        allSanityFinishCategory(sort: { fields: orderRank, order: ASC }) {
          edges {
            node {
              id
              _rawDescription(resolveReferences: { maxDepth: 10 })
              title
              orderRank
              isHidden
              slug {
                current
              }
            }
          }
        }
      }
    `,
  );
  return finishCategories;
};

export default useFinishCategories;
