import React from 'react';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import TabNavigation from '@components/TabNavigation';
import Link from '@utility/Link';
import cn from 'classnames';
import { REGEX_TRAILING_SLASH } from '../../../common';

const CategoryNavigation = ({ heading, defaultPath, categories }) => {
  const location = useLocation();
  const path = location.pathname.replace(REGEX_TRAILING_SLASH, '');
  return (
    <div className="mt-8 lg:mt-12 mb-16 lg:mb-40">
      <div className="wrapper">
        <div className="row">
          <div className="col-12 lg:col-3 xl:offset-1">
            <h1 className="mb-12 lg:mb-0 type-sans-830 md:type-sans-1130">
              {heading}
            </h1>
          </div>
          <div className="col-12 lg:col-7 lg:offset-1 flex items-end lg:-mb-3">
            <TabNavigation>
              <li>
                <Link
                  to={defaultPath}
                  className={cn({
                    'is-active-keyframe':
                      path === defaultPath || path.match(/\/blog\/\d$/),
                  })}
                  styled={false}
                  aria-label="All"
                >
                  All
                </Link>
              </li>
              {categories.map((category) => {
                if (category.node.isHidden) return false;

                return (
                  <li key={category.node.id}>
                    <Link
                      to={`/${category.node.slug.current}`}
                      activeClassName="is-active-keyframe"
                      partiallyActive
                      styled={false}
                      aria-label={category.node.title}
                    >
                      {category.node.title}
                    </Link>
                  </li>
                );
              })}
            </TabNavigation>
          </div>
        </div>
      </div>
    </div>
  );
};

CategoryNavigation.propTypes = {
  heading: PropTypes.string.isRequired,
  defaultPath: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
};

CategoryNavigation.defaultProps = {
  heading: '',
  defaultPath: '',
  categories: [],
};

export default CategoryNavigation;
