import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './styles';

const TabNavigation = ({ children, className }) => (
  <ul className={`${className} ${styles.buttonTab}`}>{children}</ul>
);

TabNavigation.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

TabNavigation.defaultProps = {
  className: '',
};

export default TabNavigation;
