import PropTypes from 'prop-types';
import React from 'react';
import FluidImage from '@utility/FluidImage/index';
import ContentBox from '@components/ContentBox';
import Parallax from '@utility/Parallax';
import ScrollInView from '@utility/ScrollInView/index';
import Link from '@utility/Link';

const ImageTextOverlap = ({ data: { contentBox, image, reverse } }) => (
  <div className="wrapper">
    <div
      className={`row flex-col-reverse md:flex-row ${
        reverse ? 'md:flex-row-reverse' : ''
      }`}
    >
      <ScrollInView
        className={`col-10 md:col-5 relative z-10 -mt-24 md:mt-0 ${
          reverse ? 'lg:-ml-28 md-down:offset-2' : ''
        }`}
      >
        <Parallax y={[20, -20]} contain={false}>
          <ContentBox data={contentBox} />
        </Parallax>
      </ScrollInView>
      <ScrollInView
        className={`col-12 md:col-7 ${reverse ? 'lg:offset-1' : 'lg:-ml-28'}`}
      >
        <Parallax y={[-15, 15]}>
          {contentBox && contentBox.link && contentBox.link[0] && (
            <Link internalExternal to={contentBox.link[0]} className="no-after" aria-label={contentBox.link[0].ariaLabel ??  contentBox.link[0].label}>
              <FluidImage src={image} maxWidth={1420} maxHeight={1064} />
            </Link>
          )}
          {(!contentBox || !contentBox.link || !contentBox.link[0]) && (
            <FluidImage src={image} maxWidth={1420} maxHeight={1064} />
          )}
        </Parallax>
      </ScrollInView>
    </div>
  </div>
);

ImageTextOverlap.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    image: PropTypes.object.isRequired,
    contentBox: PropTypes.object.isRequired,
    reverse: PropTypes.bool,
  }),
};

ImageTextOverlap.defaultProps = {
  data: {
    heading: '',
    reverse: false,
  },
};

export default ImageTextOverlap;
